import * as React from 'react';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import Container from '@mui/material/Container';

export default function Copyright() {
  return (
    <Container style={{
        width: '100%',
        height: '6em',
        padding: '0px',
        'background-color': "#eee",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }} maxWidth="false">
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <MuiLink color="inherit" href="http://kevin.coleman.net">
          Kevin Coleman
        </MuiLink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Container>
  );
}
